import * as React from "react";
import { Link } from "gatsby";
import {
  navLinks,
  navLinkItem,
  navLinkText,
} from "./layout.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <Link to="/" className={navLinkText}>
        <StaticImage
          alt="Robot Boulevard, on software, computers and better thinking"
          src="../images/header.PNG"
        />
      </Link>
      <h4 className="sub-title">
        on software, computers and self improvements through deep thinking
      </h4>
      <nav>
        <ul className={navLinks}>
          <li className={navLinkItem}>
            <Link to="/" className={navLinkText}>
              Home
            </Link>
          </li>
          <li className={navLinkItem}>
            <Link to="/about" className={navLinkText}>
              About
            </Link>
          </li>
        </ul>
      </nav>      
    </div>
  );
};
export default Header;
