import * as React from "react";
import { Link } from "gatsby";
import {
  container,
  maincontent,
  heading,
  navLinks,
  navLinkItem,
  navLinkText,
  pagetitle
} from "./layout.module.css";
import Header from "./Header";

const Layout = ({ pageTitle, children }) => {

  return (
    <div className={container}>
      <Header />
      <main className={maincontent}>
        {children}
      </main>
    </div>
  );
};
export default Layout;
