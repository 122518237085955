import * as React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date
          title
        }
        id
        body
        slug
      }
    }
  }
`;

// markup
const IndexPage = ({ data }) => {
  console.log(data);
  return (
    <Layout pageTitle="Home">
      {data.allMdx.nodes.map((node) => (
        <div key={node.id} style={{ "backgounrColor":"red" }}>
        <article key={node.id}>
          <h2>
            <Link to={`/${node.slug}`}>{node.frontmatter.title}</Link>
          </h2>
          <p>Posted: {node.frontmatter.date}</p>
        </article>
        </div>
      ))}
    </Layout>
  );
};

export default IndexPage;
